@import '_variable.scss';

body {
  font-size: 0.825rem;
}

input {
  &.form-control,
  &.form-select {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: 0;
      box-shadow: none;
    }
  }
}

.form-select {
  &:focus {
    box-shadow: none;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #495057;
}

.btn {
  height: 44px;
  min-width: 75px;
}

.form-control,
.form-select {
  height: 44px;
}

textarea {
  height: auto !important;
}

a {
  cursor: pointer !important;
}

.text-gray {
  color: #dfdfdf;

  svg {
    color: #dfdfdf;

    path {
      stroke: #dfdfdf;
    }
  }
}

.auth-content {
  min-height: calc(100vh - 136px);
}

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--bs-gray-400);
}

::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-600);
}

input[type='file'] {
  cursor: pointer;
}

hr {
  opacity: 0.25 !important;
}

.card {
  border: 0 !important;
}
.card-wrapper {
  border-radius: 0.25rem;
  box-shadow: 0 0 0.875rem 0 rgba(41, 48, 66, 0.05);
  background: white;
  &.card-md {
    @media (min-width: 768px) {
      width: 60%;
    }
  }
  &.card-lg {
    @media (min-width: 768px) {
      width: 75%;
    }
  }
  .card-header {
    padding: 1.5rem 1.5rem 0;
    color: #495057;
    font-size: 0.95rem;
    font-weight: 500;
  }
  .card-body {
    padding-inline: 1.5rem;
    padding-block: 1rem;
  }
  .label,
  .form-label {
    color: #737d85;
    font-size: 0.725rem;
    font-weight: 500;
    margin-bottom: 0.35rem;
  }
  .form-control,
  .form-select {
    font-size: 0.825rem;
  }
  p {
    font-size: 14px;
  }
}
// SOHAIB CSS
.modal-body {
  .label,
  .form-label {
    color: #737d85;
    font-size: 0.725rem;
    font-weight: 500;
    margin-bottom: 0.35rem;
  }
  .form-control,
  .form-select {
    font-size: 0.825rem;
  }
  p {
    font-size: 14px;
  }
}

.card-wrapper {
  border-radius: 0.25rem;
  box-shadow: 0 0 0.875rem 0 rgba(41, 48, 66, 0.05);
  background: white;

  &.card-md {
    @media (min-width: 768px) {
      width: 60%;
    }
  }

  .card-header {
    padding: 1.5rem 1.5rem 0;
    color: #495057;
    font-size: 0.95rem;
    font-weight: 500;
  }

  .card-body {
    padding-inline: 1.5rem;
    padding-block: 1rem;
  }

  .label,
  .form-label {
    color: #737d85;
    font-size: 0.725rem;
    font-weight: 500;
    margin-bottom: 0.35rem;
  }

  .form-control,
  .form-select {
    font-size: 0.825rem;
  }

  table {
    margin-bottom: 0;

    th {
      white-space: nowrap !important;
    }

    td,
    th {
      font-size: 12px;
      padding: 1rem;
      vertical-align: middle;

      .action-list {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        feather-icon {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
        }
      }
    }

    tbody {
      tr {
        &:last-child {
          th,
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.f14 {
  font-size: 14px !important;
}

.f12 {
  font-size: 12px !important;
}

.f10 {
  font-size: 10px !important;
}

.upload-file-outer {
  display: flex;
  gap: 0.75rem;
  height: 44px;
  width: 100%;
  padding: 4px 10px;
  border-radius: 0.25rem;
  transition: 0.3s;
  align-items: center;
  border: 1px solid $border-color;
  .verify {
    background: #35ae76;
    color: white;
    position: absolute;
    top: -12px;
    right: -5px;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    feather-icon {
      width: 14px;
      height: 16px;
      margin-top: -6px;
    }
  }
  .file-icon {
    width: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    background: var(--bs-primary);
    border-radius: 0.25rem;
    svg {
      color: white;
      path {
        fill: white;
      }
    }
  }

  span {
    font-size: 14px;
    color: var(--bs-gray-600);
  }
}

.upload-file-outer-without-border {
  display: flex;
  gap: 0.75rem;
  height: 44px;
  width: 100%;
  padding: 4px 10px;
  border-radius: 0.25rem;
  transition: 0.3s;
  align-items: center;
  .verify {
    background: #35ae76;
    color: white;
    position: absolute;
    top: -12px;
    right: -5px;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    feather-icon {
      width: 14px;
      height: 16px;
      margin-top: -6px;
    }
  }
  .file-icon {
    width: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    background: var(--bs-primary);
    border-radius: 0.25rem;
    svg {
      color: white;
      path {
        fill: white;
      }
    }
  }

  span {
    font-size: 14px;
    color: var(--bs-gray-600);
  }
}

.add-users-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  width: 100%;
  padding: 4px 10px;
  border-radius: 10px;
  transition: 0.3s;
  border: 2px solid $border-color;
  height: 150px;

  .file-icon {
    width: 36px;
    display: inline-block;
    text-align: center;
    line-height: 2.5;
    background: var(--bs-danger);
    border-radius: 8px;
    margin-block: 4px;

    svg {
      color: white;

      path {
        fill: white;
      }
    }
  }

  span {
    font-size: 14px;
    color: var(--bs-gray-600);
  }
}

form p {
  font-size: 14px;
}

.bg-primary-50 {
  background: #ffbf2396;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-monsterat {
  font-family: 'Montserrat', sans-serif !important;
}

.badge {
  min-width: 70px;
  padding: 6px 0.6em !important;
  font-size: 0.65rem !important;
  &.badge-soft-success {
    color: color-yiq(rgba(75, 191, 115, 0.175));
    background-color: rgba(75, 191, 115, 0.175);
    color: #4bbf73 !important;
  }

  &.badge-soft-danger {
    background-color: rgba(217, 83, 79, 0.175);
    color: color-yiq(rgba(217, 83, 79, 0.175));
    color: #d9534f !important;
  }

  &.badge-soft-warning {
    background-color: #ffebad;
    color: #cf9c04 !important;
  }
}

.smaller {
  font-size: 0.75rem;
}

.btn {
  font-size: 0.875rem !important;
}

.btn-primary {
  background: #367df0 !important;
  border-color: #367df0 !important;
}

.mx-300 {
  max-width: 300px;
}

hr {
  color: $border-color !important;
  opacity: 1 !important;
}

.img-thumb {
  width: 100%;
  height: 75px;
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;

  img {
    width: 75px;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}

.view-desc {
  display: flex;
  font-size: 0.75rem !important;
  line-height: 2;
  color: #495057;
  span {
    width: 280px;
    min-width: 280px;
    font-weight: 700;
    display: block;
    color: $body-color;
  }
}

.form-control:disabled {
  background-color: #f7f7f7 !important;
}

.card {
  position: relative;

  .card-header {
    background-color: white;
    border-bottom: none;
    color: var(--bs-card-cap-color);
    margin-bottom: 0;
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  }

  .card-img-top {
    height: 200px;
    max-width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
      object-fit: contain;
    }
  }

  .attachment {
    position: absolute;
    background: #ffffffcc;
    padding: 1rem;
    border-bottom-right-radius: var(--bs-border-radius);
    cursor: pointer;
    feather-icon {
      margin-bottom: 2px;
    }
  }
}

ngx-intl-tel-input {
  .separate-dial-code {
    width: 100%;
  }
}

.iti {
  font-size: 0.825rem;

  .country-dropdown {
    font-size: 0.75rem !important;
  }

  input#country-search-box {
    line-height: 2 !important;
    padding-block: 10px;
    &:focus,
    &:focus-visible {
      outline: 0 !important;
    }
  }
}
.modal {
  .modal-header {
    border: 0;
    padding-bottom: 0;
    .btn-close {
      font-size: 0.75rem !important;
    }
  }
  .modal-body {
    .form-control,
    .form-select {
      font-size: 0.825rem;
    }
  }
  .modal-footer {
    border: 0;
    padding-block: 0;
    .btn {
      font-size: 0.75rem !important;
    }
  }
}
.modal-capture {
  margin-top: 14px;
  margin-inline: 0;
  padding-inline: 0;
  background: black;
  position: relative;
  padding-bottom: 44px !important;
  .snapPhoto {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #d6d4d4;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.country-drop {
  position: relative;
  .separate-dial-code {
    position: inherit !important;
    .iti__flag-container {
      position: inherit !important;
      .iti__selected-flag {
        position: absolute !important;
      }
      .country-dropdown {
        width: 100% !important;
        max-width: 100%;
        .iti__country-name,
        .iti__country-list {
          white-space: inherit !important;
        }
      }
    }
  }
}
.theme-select2 {
  padding: 0 !important;
  .select2 {
    width: 100% !important;
    padding-inline: 0.75rem;
    height: 44px !important;
    .select2-selection__rendered {
      padding-left: 0 !important;
      line-height: 44px !important;
    }
    .select2-selection__arrow {
      height: 42px !important;
    }
  }
}

.select2-dropdown {
  border: 1px solid var(--bs-border-color) !important;
}
