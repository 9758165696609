$font-family-base: 'Poppins', sans-serif;
$body-font-size: 0.825rem;

$primary: #2d6bcf;
$primary-50: #ffbf2380;
$secondary: #ffbf23;
$secondary-500: #a0138e;
$light: #f8f9fa;
$dark: #000000;
$border-color: #eeeeee;


$border-color: #d4d4d4;
$border-radius: 0.25rem;
$body-bg: #f7f9fc;
$body-color: #6c757d;
$input-bg: white;
$input-border-color: $border-color;
$form-select-border-color: $border-color;
$btn-font-size: 0.825rem;