/* You can add global styles to this file, and also import other style files */
@import "../src/assets/style/theme.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.center {
    text-align: center;
}

.cdk-overlay-pane {
    z-index: 1056 !important;
}

.cdk-overlay-container {
    z-index: 1056 !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 1056 !important;
}

.select2-container--default .select2-selection--single {
    border: none !important;
}

.ng-select-container {
    height: 44px !important;
}